<template>
    <div class="recScript">
        <b-button
            size="is-small"
            icon-left="copy"
            class="copyBtn"
            @click="copy"
        >
            {{ copyBtnText }}
        </b-button>
        <pre
            class="hljs"
            style="display: block; overflow-x: auto; padding: 0.5em; background: rgb(253, 246, 227); color: rgb(101, 123, 131);"
        >&lt;!-- Begin SessionScoop --&gt;
<span class="xml"><span class="hljs-tag">&lt;<span class="hljs-name" style="color: rgb(38, 139, 210);">script</span>&gt;</span><span class="javascript">
<span class="hljs-built_in" style="color: rgb(220, 50, 47);">window</span>._scoop = {uid: <span class="hljs-string" style="color: rgb(42, 161, 152);">''</span>, tags: {}, clientId: <span class="hljs-string" style="color: rgb(42, 161, 152);">'{{ accountId }}'</span>}
</span><span class="hljs-tag">&lt;/<span class="hljs-name" style="color: rgb(38, 139, 210);">script</span>&gt;</span></span>
&lt;<span class="hljs-name" style="color: rgb(38, 139, 210);">script</span> <span class="hljs-keyword" style="color: rgb(133, 153, 0);">async</span> src=<span class="hljs-string" style="color: rgb(42, 161, 152);">"{{ scriptSrc }}"</span>&gt;<span class="xml"><span class="hljs-tag">&lt;/<span class="hljs-name" style="color: rgb(38, 139, 210);">script</span>&gt;</span></span>
&lt;!-- End SessionScoop --&gt;
</pre>
    </div>
</template>

<script>
export default {
    name: 'RecordingScript',
    props: {
        accountId: {
            type: String,
        },
    },
    data() {
        return {
            copyBtnText: 'Copy',
            scriptSrc: window.location.origin + '/js/scoop.js',
        }
    },
    methods: {
        copy() {
            let code = `&lt;!-- Begin SessionScoop --&gt;
&lt;script&gt;
window._scoop = {uid: '', tags: {}, clientId: '${this.accountId}'}
&lt;/script&gt;
&lt;script async src="${this.scriptSrc}"&gt;&lt;/script&gt;
&lt;!-- End SessionScoop --&gt;`
            code = code.replace(/&lt;/g, '<').replace(/&gt;/g, '>')
            const doc = document
            const body = doc.body
            const txt = doc.createElement('textarea')
            txt.value = code
            txt.style.position = 'absolute'
            txt.style.left = '-9999px'
            body.appendChild(txt)
            txt.select()
            doc.execCommand('copy')
            body.removeChild(txt)
            this.copyBtnText = 'Copied!'
            setTimeout(() => {
                this.copyBtnText = 'Copy'
            }, 1000)
        },
    },
}
</script>
