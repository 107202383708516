<template>
    <default-layout class="docsPage">
        <header class="pageHeader">
            <h1 class="pageTitle">
                <b-icon icon="book"></b-icon>
                Documentation
            </h1>
        </header>
        <b-tabs position="is-left" vertical class="content">
            <b-tab-item label="Installation" class="tab-item isInstall">
                <h2 class="title">Installing SessionScoop</h2>
                <p class="subtitle">
                    Installing SessionScoop only takes a few minutes, just
                    follow the 3 simple steps below to get your scoop on.
                </p>
                <div class="step">
                    <b-tag type="is-primary" size="is-large">Step 1</b-tag>
                    <p>
                        Enable your sites via your
                        <router-link :to="{ name: 'account.settings.sites' }">
                            Account Settings.
                        </router-link>
                    </p>
                </div>
                <div class="step">
                    <b-tag type="is-primary" size="is-large">Step 2</b-tag>
                    <p>
                        Copy your unique recording script below and paste it
                        into your site on the line before
                        <code>
                            &lt;/head&gt;
                        </code>
                    </p>
                    <div class="message is-info">
                        <div class="message-body">
                            The script is unique to your account and can be used
                            on all your sites.
                            <strong
                                >It's also super small and asynchronous to keep
                                from slowing your page.</strong
                            >
                        </div>
                    </div>
                    <RecordingScript :account-id="user.accountId" v-if="user" />
                    <div class="recScriptGuest" v-else>
                        <router-link :to="{ name: 'login' }">
                            Log in
                        </router-link>
                        or
                        <router-link :to="{ name: 'signup' }">
                            Sign up
                        </router-link>
                        to see your recording script.
                    </div>
                    <p>Your page should look similar to this:</p>
                    <pre
                        class="hljs"
                        style="display: block; overflow-x: auto; padding: 0.5em; background: rgb(35, 36, 31); color: rgb(248, 248, 242);"
                    ><span class="xml"><span class="hljs-meta" style="color: rgb(117, 113, 94);">&lt;!DOCTYPE html&gt;</span>
<span class="hljs-tag" style="color: rgb(248, 248, 242);">&lt;<span class="hljs-name" style="color: rgb(249, 38, 114);">html</span> <span class="hljs-attr" style="color: rgb(249, 38, 114);">lang</span>=<span class="hljs-string" style="color: rgb(230, 219, 116);">"en"</span>&gt;</span>
<span class="hljs-tag" style="color: rgb(248, 248, 242);">&lt;<span class="hljs-name" style="color: rgb(249, 38, 114);">head</span>&gt;</span>
    <span class="hljs-tag" style="color: rgb(248, 248, 242);">&lt;<span class="hljs-name" style="color: rgb(249, 38, 114);">meta</span> <span class="hljs-attr" style="color: rgb(249, 38, 114);">charset</span>=<span class="hljs-string" style="color: rgb(230, 219, 116);">"UTF-8"</span> /&gt;</span>
    <span class="hljs-tag" style="color: rgb(248, 248, 242);">&lt;<span class="hljs-name" style="color: rgb(249, 38, 114);">meta</span> <span class="hljs-attr" style="color: rgb(249, 38, 114);">name</span>=<span class="hljs-string" style="color: rgb(230, 219, 116);">"viewport"</span> <span class="hljs-attr" style="color: rgb(249, 38, 114);">content</span>=<span class="hljs-string" style="color: rgb(230, 219, 116);">"width=device-width, initial-scale=1.0"</span> /&gt;</span>
    <span class="hljs-tag" style="color: rgb(248, 248, 242);">&lt;<span class="hljs-name" style="color: rgb(249, 38, 114);">title</span>&gt;</span>My Site<span class="hljs-tag" style="color: rgb(248, 248, 242);">&lt;/<span class="hljs-name" style="color: rgb(249, 38, 114);">title</span>&gt;</span>
    <span class="hljs-comment" style="color: rgb(117, 113, 94);">&lt;!-- Begin SessionScoop --&gt;</span>
    <span class="hljs-tag" style="color: rgb(248, 248, 242);">&lt;<span class="hljs-name" style="color: rgb(249, 38, 114);">script</span>&gt;</span><span class="javascript">
        <span class="hljs-built_in" style="color: rgb(230, 219, 116);">window</span>._scoop = {uid: <span class="hljs-string" style="color: rgb(230, 219, 116);">''</span>, tags: {}, clientId: <span class="hljs-string" style="color: rgb(230, 219, 116);">'5fde5effb15b4e1361c6080e'</span>}
    </span><span class="hljs-tag" style="color: rgb(248, 248, 242);">&lt;/<span class="hljs-name" style="color: rgb(249, 38, 114);">script</span>&gt;</span>
    <span class="hljs-tag" style="color: rgb(248, 248, 242);">&lt;<span class="hljs-name" style="color: rgb(249, 38, 114);">script</span> <span class="hljs-attr" style="color: rgb(249, 38, 114);">async</span> <span class="hljs-attr" style="color: rgb(249, 38, 114);">src</span>=<span class="hljs-string" style="color: rgb(230, 219, 116);">"https://sessionscoop.com/js/scoop.js"</span>&gt;</span><span class="undefined"></span><span class="hljs-tag" style="color: rgb(248, 248, 242);">&lt;/<span class="hljs-name" style="color: rgb(249, 38, 114);">script</span>&gt;</span>
    <span class="hljs-comment" style="color: rgb(117, 113, 94);">&lt;!-- End SessionScoop --&gt;</span>
<span class="hljs-tag" style="color: rgb(248, 248, 242);">&lt;/<span class="hljs-name" style="color: rgb(249, 38, 114);">head</span>&gt;</span>
<span class="hljs-tag" style="color: rgb(248, 248, 242);">&lt;<span class="hljs-name" style="color: rgb(249, 38, 114);">body</span>&gt;</span></span></pre>
                </div>
                <div class="step">
                    <b-tag type="is-primary" size="is-large">Step 3</b-tag>
                    <p>
                        Deploy your site and boom! SessionScoop will immediately
                        begin recording user sessions.
                    </p>
                </div>
            </b-tab-item>
            <b-tab-item label="Identify Users">
                <h2 class="title">Identifying Users</h2>
                <p>
                    Passing a unique identifier
                    <code>UID</code>
                    in your recording script lets you associate each session
                    recording with a meaningful attribute such as <i>email</i>,
                    <i>username</i>, or <i>server session ID</i>, that uniquely
                    identifies the user or session. Allowing you to easily track
                    and browse your session recordings.
                </p>
                <p>
                    Example:
                </p>
                <p>
                    The below configuration will associate the current session
                    with an arbitrary server session ID
                    <code>Q6Q6xJAortMfK1rZDTgZ</code>.
                </p>
                <pre
                    class="hljs"
                    style="display: block; overflow-x: auto; padding: 0.5em; background: rgb(35, 36, 31); color: rgb(248, 248, 242);"
                ><span class="xml"><span class="hljs-comment" style="color: rgb(117, 113, 94);">&lt;!-- Begin SessionScoop --&gt;</span>
<span class="hljs-tag" style="color: rgb(248, 248, 242);">&lt;<span class="hljs-name" style="color: rgb(249, 38, 114);">script</span>&gt;</span><span class="javascript">
    <span class="hljs-built_in" style="color: rgb(230, 219, 116);">window</span>._scoop = </span></span><span class="hljs-template-variable" style="color: rgb(230, 219, 116);">{}</span><span class="xml"><span class="actionscript">;
    _scoop.uid = <span class="hljs-string" style="color: rgb(230, 219, 116);">'Q6Q6xJAortMfK1rZDTgZ'</span>;
    _scoop.tags = </span></span><span class="hljs-template-variable" style="color: rgb(230, 219, 116);">{}</span><span class="xml"><span class="actionscript">;
    _scoop.clientId = <span class="hljs-string" style="color: rgb(230, 219, 116);">'5fde5effb15b4e1361c6080e'</span>;
</span><span class="hljs-tag" style="color: rgb(248, 248, 242);">&lt;/<span class="hljs-name" style="color: rgb(249, 38, 114);">script</span>&gt;</span>
<span class="hljs-tag" style="color: rgb(248, 248, 242);">&lt;<span class="hljs-name" style="color: rgb(249, 38, 114);">script</span> <span class="hljs-attr" style="color: rgb(249, 38, 114);">async</span> <span class="hljs-attr" style="color: rgb(249, 38, 114);">src</span>=<span class="hljs-string" style="color: rgb(230, 219, 116);">"https://sessionscoop.com/js/scoop.js"</span>&gt;</span><span class="undefined"></span><span class="hljs-tag" style="color: rgb(248, 248, 242);">&lt;/<span class="hljs-name" style="color: rgb(249, 38, 114);">script</span>&gt;</span>
<span class="hljs-comment" style="color: rgb(117, 113, 94);">&lt;!-- End SessionScoop --&gt;</span></span></pre>
            </b-tab-item>
            <b-tab-item label="Tag User Sessions">
                <h2 class="title">Tagging User Sessions</h2>
                <p>
                    Tags let you pass in additional information you have about
                    the user or session to be tied in with the session recording
                    for viewing and searching. This lets you track and search
                    for sessions by any custom attribute like <i>email</i>,
                    <i>username</i>, or <i>display name</i>.
                </p>
                <p>
                    You can add up to
                    <code>20</code>
                    tags consisting of simple
                    <code>key/value</code>
                    pairs of no more than
                    <code>20/100</code>
                    characters in length, respectively.
                </p>
                <p>
                    Example:
                </p>
                <p>
                    The below configuration creates a tag with the key
                    <code>email</code> having the value
                    <code>donjuan@example.com</code>.
                </p>
                <pre
                    class="hljs"
                    style="display: block; overflow-x: auto; padding: 0.5em; background: rgb(35, 36, 31); color: rgb(248, 248, 242);"
                ><span class="xml"><span class="hljs-comment" style="color: rgb(117, 113, 94);">&lt;!-- Begin SessionScoop --&gt;</span>
<span class="hljs-tag" style="color: rgb(248, 248, 242);">&lt;<span class="hljs-name" style="color: rgb(249, 38, 114);">script</span>&gt;</span><span class="javascript">
    <span class="hljs-built_in" style="color: rgb(230, 219, 116);">window</span>._scoop = </span></span><span class="hljs-template-variable" style="color: rgb(230, 219, 116);">{}</span><span class="xml"><span class="actionscript">;
    _scoop.uid = <span class="hljs-string" style="color: rgb(230, 219, 116);">'Q6Q6xJAortMfK1rZDTgZ'</span>;
    _scoop.tags = </span></span><span class="hljs-template-variable" style="color: rgb(230, 219, 116);">{
        email: 'donjuan@example.com'
    }</span><span class="xml"><span class="actionscript">;
    _scoop.clientId = <span class="hljs-string" style="color: rgb(230, 219, 116);">'5fde5effb15b4e1361c6080e'</span>;
</span><span class="hljs-tag" style="color: rgb(248, 248, 242);">&lt;/<span class="hljs-name" style="color: rgb(249, 38, 114);">script</span>&gt;</span>
<span class="hljs-tag" style="color: rgb(248, 248, 242);">&lt;<span class="hljs-name" style="color: rgb(249, 38, 114);">script</span> <span class="hljs-attr" style="color: rgb(249, 38, 114);">async</span> <span class="hljs-attr" style="color: rgb(249, 38, 114);">src</span>=<span class="hljs-string" style="color: rgb(230, 219, 116);">"https://sessionscoop.com/js/scoop.js"</span>&gt;</span><span class="undefined"></span><span class="hljs-tag" style="color: rgb(248, 248, 242);">&lt;/<span class="hljs-name" style="color: rgb(249, 38, 114);">script</span>&gt;</span>
<span class="hljs-comment" style="color: rgb(117, 113, 94);">&lt;!-- End SessionScoop --&gt;</span></span></pre>
            </b-tab-item>
            <b-tab-item label="User Privacy">
                <h2 class="title">User Privacy</h2>
                <p class="subtitle">
                    <i>Privacy is top priority.</i>
                </p>
                <p>
                    We highly value your privacy and the privacy of your
                    customers and as such have taken a privacy-first approach to
                    ensure that sensitive customer information is kept private.
                </p>
                <p>
                    You can exclude any content on your site (like credit card
                    inputs or a DIV with private info) from being recorded and
                    sent to SessionScoop by marking it as sensitive.
                </p>
                <h3>Excluding HTML Content</h3>
                <p>
                    To exclude any content from recording, simply add the class
                    <code>scoop-block</code>
                    to the element. All excluded elements will replay as
                    rectangles of the same dimension.
                </p>
                <p>Example:</p>
                <pre
                    class="hljs"
                    style="display: block; overflow-x: auto; padding: 0.5em; background: rgb(35, 36, 31); color: rgb(248, 248, 242);"
                >&lt;div <span class="hljs-class"><span class="hljs-keyword" style="color: rgb(249, 38, 114);">id</span></span>=<span class="hljs-string" style="color: rgb(230, 219, 116);">"private-info"</span> <span class="hljs-class"><span class="hljs-keyword" style="color: rgb(249, 38, 114);">class</span></span>=<span class="hljs-string" style="color: rgb(230, 219, 116);">"scoop-block"</span>&gt;...&lt;/div&gt;
</pre>
                <p>This works for input fields too:</p>
                <pre
                    class="hljs"
                    style="display: block; overflow-x: auto; padding: 0.5em; background: rgb(35, 36, 31); color: rgb(248, 248, 242);"
                >&lt;input <span class="hljs-class"><span class="hljs-keyword" style="color: rgb(249, 38, 114);">type</span></span>=<span class="hljs-string" style="color: rgb(230, 219, 116);">"text"</span> name=<span class="hljs-string" style="color: rgb(230, 219, 116);">"ccNumber"</span> <span class="hljs-class"><span class="hljs-keyword" style="color: rgb(249, 38, 114);">class</span></span>=<span class="hljs-string" style="color: rgb(230, 219, 116);">"scoop-block"</span> /&gt;
</pre>
                <h3>Excluding Input Fields</h3>
                <div class="message is-warning">
                    <div class="message-body">
                        <strong>NOTE:</strong> Password fields are excluded by
                        default.
                    </div>
                </div>
                <p>
                    Excluded input fields will have their keypresses blocked
                    from recording. Yet, keep in mind that initial values will
                    still be recorded.
                </p>
                <p>
                    You can mark an input field as sensitive by adding the class
                    <code>scoop-ignore</code>
                </p>
                <p>Example:</p>
                <pre
                    class="hljs"
                    style="display: block; overflow-x: auto; padding: 0.5em; background: rgb(35, 36, 31); color: rgb(248, 248, 242);"
                >&lt;input <span class="hljs-class"><span class="hljs-keyword" style="color: rgb(249, 38, 114);">type</span></span>=<span class="hljs-string" style="color: rgb(230, 219, 116);">"text"</span> name=<span class="hljs-string" style="color: rgb(230, 219, 116);">"ccNumber"</span> <span class="hljs-class"><span class="hljs-keyword" style="color: rgb(249, 38, 114);">class</span></span>=<span class="hljs-string" style="color: rgb(230, 219, 116);">"scoop-ignore"</span> /&gt;
</pre>
                <h3>Masking All Input Fields</h3>
                <p>
                    Passing the following option in your recording script will
                    mask all input fields.
                </p>
                <pre
                    class="hljs"
                    style="display: block; overflow-x: auto; padding: 0.5em; background: rgb(35, 36, 31); color: rgb(248, 248, 242);"
                >_scoop.maskAllInputs = <span class="hljs-literal" style="color: rgb(174, 129, 255);">true</span>;
</pre>
                <p>Masked fields appear like so in recordings:</p>
                <input type="password" value="hello" readonly />
            </b-tab-item>
        </b-tabs>
    </default-layout>
</template>

<script>
import RecordingScript from '@/components/Code/RecordingScript'
import { mapGetters } from 'vuex'

export default {
    name: 'Docs',
    components: { RecordingScript },
    computed: {
        ...mapGetters({ user: 'account/user' }),
    },
}
</script>
